import React, { useCallback, useEffect, useState } from "react";
import './styles/App.css';
import twitterLogo from './assets/twitter-logo.svg';
import discordLogo from './assets/discord-logo.svg';
import openseaLogo from './assets/opensea-logo.svg';
import etherscanLogo from './assets/etherscan-logo.svg';
import coinbaseLogo from './assets/coinbase.png';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import WalletLink from 'walletlink';

import LarvaCrabsGIF from './assets/larvacrabs_unknown.gif';
import LarvaCrabsABI from './utils/LarvaCrabsABI.json';
import { Web3Provider } from "walletlink/dist/provider/Web3Provider";
import { walletconnect } from "web3modal/dist/providers/connectors";

const DEV_TWITTER_HANDLE = 'daileytj';
const DEV_TWITTER_LINK = `https://twitter.com/${DEV_TWITTER_HANDLE}`;
const OPENSEA_COLLECTION_LINK = 'https://opensea.io/collection/the-larva-crabs';
// const TEST_OPENSEA_COLLECTION_LINK = 'https://testnets.opensea.io/collection/larva-crabs';
const TOTAL_MINT_COUNT = 5000;
const DISPLAY_PRICE = "0.005";
const CONTRACT_ADDRESS = '0xdC2d0188ca0A6766E997557ba167fC71573c9235';
// const TEST_CONTRACT_ADDRESS = '0x3d7E5C91704506bA399F1e2204A9c1B6cC191cfA';
const PROJECT_TWITTER_HANDLE = 'larvacrabs_'
const PROJECT_TWITTER_LINK = `https://twitter.com/${PROJECT_TWITTER_HANDLE}`;
const DISCORD_LINK = 'https://discord.gg/kcrPz3fHnS'

const App = () => {
  const [web3Provider, setWeb3Provider] = useState<Web3Provider>();
  const [currentAccount, setCurrentAccount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfulMint, setIsSuccessfulMint] = useState(false);
  const [etherscanLink, setEtherscanLink] = useState('');
  const [mintCount, setMintCount] = useState(0);
  const [amountToMint, setAmountToMint] = useState(1);
  const [isMainnet, setIsMainnet] = useState(true);
  const [testnetMessageDismissed, setTestnetMessageDismissed] = useState(false);


  /**
     * Initialise the wallet will only fire once we have a connected wallet
     */
   useEffect(() => {
    const initWallet = async () => {
        if (web3Provider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const userAddress = provider.getSigner().getAddress();

            const caObject = new ethers.Contract(
                CONTRACT_ADDRESS as string,
                LarvaCrabsABI,
                provider.getSigner(),
            ) as any;

            // setPageState({
            //     maxSupply: (await caObject.maxSupply()).toNumber(),
            //     totalSupply: (await caObject.totalSupply()).toNumber(),
            //     maxMint: (await caObject.maxMintAmountPerTx()).toNumber(),
            //     price: await caObject.cost(),
            //     isPaused: await caObject.paused(),
            //     userAddress: userAddress,
            //     contract: caObject,
            // });
        }
    };

    initWallet();
}, [web3Provider]);


  const checkIfWalletIsConnected = async () => {

    const initWallet = async () => {
      if (web3Provider) {
          const accounts:any = await web3Provider.request({ method: 'eth_accounts' });
          const userAddress = new accounts([0])

          // const caObject = new ethers.Contract(
          //     process.env.NEXT_PUBLIC_CONTRACT as string,
          //     ContractAbi,
          //     web3Provider.getSigner(),
          // ) as GoldenTicketNFTClub;

          // setPageState({
          //     maxSupply: (await caObject.maxSupply()).toNumber(),
          //     totalSupply: (await caObject.totalSupply()).toNumber(),
          //     maxMint: (await caObject.maxMintAmountPerTx()).toNumber(),
          //     price: await caObject.cost(),
          //     isPaused: await caObject.paused(),
          //     userAddress: userAddress,
          //     contract: caObject,
          // });
      }
  };

  initWallet();
};
    // const { ethereum } = window;

    // if (!ethereum) {
    //   console.log("Make sure you have metamask!");
    //   return;
    // } else {
    //   console.log("We have the ethereum object", ethereum);
    // }

    // const accounts = await ethereum.request({ method: 'eth_accounts' });

    // if (accounts.length !== 0) {
    //   const account = accounts[0];
    //   setCurrentAccount(account);

    //   let chainId = await ethereum.request({ method: 'eth_chainId' });
    //   console.log("Connected to chain " + chainId);

    //   // hex code of the chainId of the Mainnet test network
    //   const mainnetChainId = "0x1";
    //   if (chainId !== mainnetChainId) {
    //     setIsMainnet(false);
    //   }

    //   getMintCount();
    // } else {
    //   console.log("No authorized account found")
    // }
  // }


  const connectWallet = async () => {
    const providerOptions = {
      walletconnect: {
          display: {
              logo: '/assets/images/coinbase.png',
              name: 'Wallet Connect',
              description: 'Connect with WalletConect',
          },
          package: WalletConnectProvider, // required
          rpc: {
            1: "https://mainnet.infura.io/v3/"
          },
          options: {
              chainId: 1,
          },
      },

      injected: {
          display: {
              logo: 'https://github.com/MetaMask/brand-resources/raw/master/SVG/metamask-fox.svg',
              name: 'MetaMask',
              description: 'Connect with MetaMask in your browser',
          },
          package: null,
      },
    };
    try {
      const web3Modal = new Web3Modal({
          cacheProvider: false, // optional
          providerOptions, // required
      });

      // const connection = await web3Modal.connect();
      const provider = await web3Modal.connect();
      // if (this) {
      //   await this.subscribeProvider(provider);
      // }
      
      await provider.enable();
      // const provider = new ethers.providers.Web3Provider(connection);
      const accounts = await provider.request({ method: "eth_requestAccounts" });
      setCurrentAccount(accounts[0]);
      setWeb3Provider(provider);

  } catch (e: any) {
      console.log(e.message);
  }

    // try {
    //   const { ethereum } = window;

    //   if (!ethereum) {
    //     alert("Get MetaMask!");
    //     return;
    //   }

    //   const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    //   setCurrentAccount(accounts[0]);

    //   let chainId = await ethereum.request({ method: 'eth_chainId' });
    //   console.log("Connected to chain " + chainId);

    //   // hex code of the chainId of the Mainnet test network
    //   const mainnetChainId = "0x1";
    //   if (chainId !== mainnetChainId) {
    //     setIsMainnet(false);
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const askContractToMintNft = async () => {
    setIsSuccessfulMint(false);

    try {
      const { ethereum } = window;

      if (ethereum) {
        
        // const provider = web3Provider;

        // const provider = window['ethereum'] || window.web3.currentProvider
        // const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        // const provider = web3Provider;
        const provider = ethers.getDefaultProvider();
        const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
        // await provider?.send("eth_requestAccounts", []);
        // const signer = provider?.getSigner();
        // const signer =  await (web3Provider as any).getSigner();
        const contract = new ethers.Contract(CONTRACT_ADDRESS, LarvaCrabsABI, signer);

        const connectedContract = contract.connect(signer)

        

        // const estimation = await connectedContract.estimateGas.mint(1);

        var cost = 0.005 * amountToMint;
        var costWei = ethers.utils.parseEther(cost.toString());
        
        // let estimate = await connectedContract.estimateGas.mint(amountToMint);

        const overrides: any = {
          from: signer.getAddress(),
          value: costWei
        }

      // const gasBN = await contract.methods.mint(costWei,amountToMint).estimateGas({ from: overrides.from });
      // const gasBN = await contract.estimateGas.mint(amountToMint);
      
      // const gasBN = await provider?.getFeeData(); 
      // const gasLimit = await connectedContract?.estimateGas.mint(amountToMint);

      // // throw new Error("test");

      // const finalGasBN = gasBN.maxFeePerGas?.mul( ethers.BigNumber.from(11) ).div( ethers.BigNumber.from(10) );
      // overrides.gasLimit = finalGasBN?.toString();

      const nftTxn = await connectedContract.mint(amountToMint, overrides)
      await nftTxn.wait();

        // let nftTxn = await connectedContract.mint(amountToMint,
        //    { value: costWei,
        //      gasLimit: 71518,
        //      maxPriorityFeePerGas: String(1858000000) } );
        setIsLoading(true);

        await nftTxn.wait();
        setIsLoading(false);
        setIsSuccessfulMint(true);
        setEtherscanLink(`https://etherscan.io/tx/${nftTxn.hash}`);
        getMintCount();
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error:any) {
      
        alert(error.message);
      
      
      console.log(error)
    }
  }

  const getMintCount = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        // const provider:Web3Provider = web3Provider!;
        const signer = ethereum.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, LarvaCrabsABI, signer);
        let nftCount = await connectedContract.totalSupply() - 1; //account for 0 based array
        setMintCount(parseInt(nftCount.toString(), 10));
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateAmountToMint = useCallback((operation) => {
    if (operation === 'subtract' && amountToMint > 1) {
      setAmountToMint(amountToMint - 1);
    }
    if (operation === 'add' && amountToMint < 10) {
      setAmountToMint(amountToMint + 1);
    }
  }, [amountToMint]);

  const getButtonRow = () => {
    if (currentAccount === "") {
      return (<button onClick={connectWallet} className="cta-button connect-wallet-button">
        Connect to Wallet
      </button>)
    } return (
      <div>
        <button onClick={() => updateAmountToMint('subtract')} className="cta-button subtract-button" disabled={isLoading || (amountToMint <= 1) || (mintCount === TOTAL_MINT_COUNT)}>-</button>
        <button onClick={askContractToMintNft} className="cta-button mint-button" disabled={isLoading || (mintCount === TOTAL_MINT_COUNT)}>
          {isLoading ? 'Minting...' : `Mint ${amountToMint}`}
        </button>
        <button onClick={() => updateAmountToMint('add')} className="cta-button add-button" disabled={isLoading || (amountToMint >= 10) || (mintCount === TOTAL_MINT_COUNT)}>+</button>
      </div>
    )
  };

  const navigateToOpensea = () => {
    window.open(OPENSEA_COLLECTION_LINK, '_blank');
  }

  const navigateToTwitter = () => {
    window.open(PROJECT_TWITTER_LINK, '_blank');
  }

  const navigateToDiscord = () => {
    window.open(DISCORD_LINK, '_blank');
  }

  const navigateToEtherscan = () => {
    window.open(`https://etherscan.io/address/${CONTRACT_ADDRESS}`, '_blank');
  }

  useEffect(() => {
    checkIfWalletIsConnected();
    getMintCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount])

  return (
    <div className="App">
      <div className="appbar">
        <button className="appbar-button" onClick={navigateToTwitter}>
          <img alt="Twitter Logo" className="appbar-logo appbar-twitter-logo" src={twitterLogo} />
        </button>
        <button className="appbar-button" onClick={navigateToDiscord}>
          <img alt="Discord Logo" className="appbar-logo appbar-discord-logo" src={discordLogo} />
        </button>
        <button className="appbar-button" onClick={navigateToOpensea}>
          <img alt="Opensea Logo" className="appbar-logo appbar-opensea-logo" src={openseaLogo} />
        </button>
        <button className="appbar-button" onClick={navigateToEtherscan}>
          <img alt="Etherscan Logo" className="appbar-logo appbar-etherscan-logo" src={etherscanLogo} />
        </button>
      </div>
      <div className="container">
        <div className="content-container">
          <p className="header gradient-text">Larva Crabs</p>
          <p className="sub-text">
            5000 Larva Crabs that are randomly generated and minted on the Ethereum blockchain. Mint yours now and join the Crab Rave!
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <img src={LarvaCrabsGIF} alt={'Larva Crabs Unknown'} className="larva-crabs-gif" />
          <p className="mint-price">1 Larva Crab costs {DISPLAY_PRICE} ETH <br/>On our Low Gas Contract</p>
          {currentAccount !== "" && (<p className="wallet-address-text">{currentAccount} is connected!</p>)}
          {getButtonRow()}
          {mintCount < TOTAL_MINT_COUNT && mintCount > 0 && (<p className="mint-count">{mintCount}/{TOTAL_MINT_COUNT} Minted</p>)}
          {mintCount === TOTAL_MINT_COUNT && (<p className="mint-count">Sold Out!</p>)}
          {isSuccessfulMint && (
            <div className="success-message">
              <p>Successful Mint!</p>
              <p>See your transaction on <a className="etherscan-link" href={etherscanLink} target="_blank" rel="noreferrer">Etherscan</a></p>
              <p>View your NFT on <a className="opensea-link" href={OPENSEA_COLLECTION_LINK} target="_blank" rel="noreferrer">Opensea</a></p>
            </div>
          )}
        </div>
        <div>
          <p className="sub-heading gradient-text">Future Vision for Larva Crabs // Community Takeover Project</p>
          <p className="sub-text">At Larva Crabs we’re all about having fun.  
          We’re a driven group committed to making this work for all Holders.  
          Dedicated to building up value of Larva Crabs through metaverse integration, 
          community led initiatives and charitable contributions.</p>
        </div>
        <div className="footer-container">
          
          <img alt="Twitter Logo" className="dev-twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href={DEV_TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >{`built by @${DEV_TWITTER_HANDLE}`}</a>
        </div>
        <div>* this project is NOT affiliated with larva labs</div>
      </div>
      {currentAccount && !isMainnet && !testnetMessageDismissed && (
        <div className="testnet-message-container">
          <p>You are currently on a testnet. Please connect to the mainnet to mint.</p>
          <button className="testnet-message-dismiss-button" onClick={() => setTestnetMessageDismissed(true)}>Dismiss</button>
        </div>
      )}
    </div>
  );
};

export default App;
